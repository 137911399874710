// noinspection DuplicatedCode

$(document).ready(function () {
   console.log('ready');
   searchElDropdown();
   handleToggleMobMenu();
   openMobileMenu();
   closeMobileMenu();
   validate('.form', {submitFunction: validationCallForm});
   validTextNumbers();
   closePopupSuccessForm();
   handleAnimationIMG()
   handleToggleItemList()
});

$(window).on('load', function () {
   console.log('load');
});

const WINDOW_WIDTH = $(document).width()
//==============================================================================================================================
const handleToggleMobMenu = () => {
   $(document).on('keydown', function (e) {
      if (e.key === 'Escape') {
         closeMobileMenu();
      }
   });

   $(document).on('click', '[data-menu-burger]', function () {
      openMobileMenu();
   });

   $(document).on('click', '[data-menu-burger-close]', function () {
      closeMobileMenu();
   });

   $(document).on('click', '.menu', function (e) {
      if (e.target === e.currentTarget) {
         closeMobileMenu();
      }
   });
};

const openMobileMenu = () => {
   refs.menu.addClass('open');
   refs.shadowBlock.removeClass('is-hidden');
   $('body').addClass('no-scroll');
   $('.menu__icon-burger').addClass('open');
};

const closeMobileMenu = () => {
   refs.menu.removeClass('open');
   refs.shadowBlock.addClass('is-hidden');
   $('body').removeClass('no-scroll');
   $('.menu__icon-burger').removeClass('open');
};

const showPopupSuccessForm = () => {
   $.fancybox.open({
      src: ".form-success",
      type: "inline",
   });
};

const closePopupSuccessForm = () => {
   $(document).on('click', '[data-close-fancybox]', function () {
      $.fancybox.close({
         src: ".form-success",
         type: "none",
      });
   });
};

const handleAnimationIMG  = () => {

   if (WINDOW_WIDTH <= 1366) {
      $(document).on('click', '.motor__img', function () {
         const ths = $(this)
         ths.toggleClass('is-hidden')
      })
   }
}

const handleToggleItemList = () => {

   const MOBILE_SIZE = 666;
   const DESKTOP_SIZE = 1024;
   const MOBILE_VIEW = 5;
   const DESKTOP_VIEW = 11;
   const dependingItemLength = refs.dependingItem.length;

   if (WINDOW_WIDTH <= MOBILE_SIZE) {

      if (dependingItemLength <= MOBILE_VIEW) {
         refs.btnMore.addClass('is-hidden');
      }

      refs.dependingItem.each(function (index) {
         const elPosition = index + 1;
         const ths = $(this);

         if (elPosition >= MOBILE_VIEW + 1) {
            ths.addClass('is-hidden');
         }
      });
   }

   if (WINDOW_WIDTH >= DESKTOP_SIZE) {

      if (dependingItemLength <= DESKTOP_VIEW) {
         refs.btnMore.addClass('is-hidden');
      }

      refs.dependingItem.each(function (index) {
         const elPosition = index + 1;
         const ths = $(this);

         if (elPosition >= DESKTOP_VIEW + 1) {
            ths.addClass('is-hidden');
         }
      });
   }

   $(document).on('click', '[data-more]', function () {

      const ths = $(this);
      refs.btnLess.removeClass('is-hidden');
      ths.addClass('is-hidden');

      refs.dependingItem.each(function () {
         const ths = $(this);
         ths.removeClass('is-hidden');
      });
   });

   $(document).on('click', '[data-less]', function () {

      const ths = $(this);
      refs.btnMore.removeClass('is-hidden');
      ths.addClass('is-hidden');

      if (WINDOW_WIDTH >= DESKTOP_SIZE) {

         if (dependingItemLength <= DESKTOP_VIEW) {
            refs.btnMore.addClass('is-hidden');
         }

         refs.dependingItem.each(function (index) {
            const elPosition = index + 1;
            const ths = $(this);

            if (elPosition >= DESKTOP_VIEW + 1) {
               ths.addClass('is-hidden');
            }
         });
      }

      if (WINDOW_WIDTH <= MOBILE_SIZE) {

         if (dependingItemLength <= MOBILE_VIEW) {
            refs.btnMore.addClass('is-hidden');
         }

         refs.dependingItem.each(function (index) {
            const elPosition = index + 1;
            const ths = $(this);

            if (elPosition >= MOBILE_VIEW + 1) {
               ths.addClass('is-hidden');
            }
         });
      }
   });
};