/* ============================ refs START ================================================================= */
const refs = {
   dropDownList: $('.dropdown__list'),
   dropDownBtn: $('.dropdown__button'),
   dropDownItems: $('.dropdown__item'),
   dropDownInput: $('.dropdown__input-hidden'),
   menu: $('.menu'),
   shadowBlock: $('.shadow'),
   dependingItem: $('.depending__item'),
   btnMore :$('[data-more]'),
   btnLess :$('[data-less]')
};
/* ============================ refs END ================================================================= */
